.home .header {
    padding: 2em 0 3em 0; }
  
  .nextpage .header {
    padding: 2em 0 1em 0; }
  
  .content {
    margin-top: 2em; }
  
  .logo {
    text-align: center; }
    .logo img {
      max-width: 300px; }
  
  .tagline {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    width: auto;
    margin-top: 3em; }
  
  .home-top .company-card {
    background: #e1e1e1;
    margin-top: 1em;
    border-radius: 4px; }
  
  .home-top .image {
    text-align: center;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    position: relative;
    border-radius: 4px 4px 0 0; }
    .home-top .image img {
      width: 100%; }
  
  .home-top .info {
    padding: 1em; }
  
  @media (min-width: 650px) {
    .home-top .row {
      display: flex; } } 
  
  .home-bottom {
    padding: 3em 20px; }
    .home-bottom .videoWrapper {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0; }
      .home-bottom .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 95%;
        height: 95%; }
      
  .person-card .gallery-cell {
    justify-content: space-between; }
  
  .person-card .image {
    width: 30%;
    float: left; }
    .person-card .image img {
      border-radius: 100%;
      height: initial; }
  
  .person-card .summary {
    width: 65%;
    float: right; }
    .person-card .summary .quote {
      font-style: italic; }
  